<template>
  <v-container fluid>
    <v-card flat class="pt-12 px-4">
      <mb-youtube-playlist />
    </v-card>
  </v-container>
</template>

<script>
// load text from file
  export default {
    name: 'Videos',
  }
</script>
<style>
</style>
