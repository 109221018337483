import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import { merchbase, mbStore, mbRouter } from '@inkbird.io/vue-merchbase'
import VideoBackground from 'vue-responsive-video-background-player'
import { Plugin } from 'vue-responsive-video-background-player'
import myRouter from './router'

Vue.use(Plugin);
Vue.component('video-background', VideoBackground);

Vue.config.productionTip = false

Vue.use(Vuex) // use Vuex before you create the store
const store = new Vuex.Store(mbStore)

Vue.use(VueRouter)
mbRouter.routes = [...myRouter.routes, ...mbRouter.routes]
const router = new VueRouter(mbRouter)

Vue.use(merchbase, { 
  store,
  shopID: process.env.VUE_APP_CONTEXT,
  shopURI: process.env.VUE_APP_SHOPURI
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
