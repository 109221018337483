import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import grapevine from '../assets/grapevine';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      dark: false,
      themes: {
        light: {
          primary: "#7DA957",
          secondary: "#039BE5",
          accent: colors.indigo.base, // #3F51B5
          background: colors.white
        },
      },
    },
    icons: {
      values: {
        grapevine: { component: grapevine }
      }
    }
  })