<template>
  <v-app theme--light>
    <!-- Nav Bar -->
    <v-app-bar
        :dark="darkMode"
        fixed
        color="rgba(0,0,0,0)"
        hide-on-scroll flat
    >
        <v-btn elevation="0" color="rgba(0,0,0,0)" @click="$router.push('/home')" text class="d-none d-sm-block">
            <v-img
                dense
                width="150"
                :src="require(`@/assets/logo_${darkMode?'white':'black'}.png`)"
            />
        </v-btn>

        <v-menu
          transition="slide-x-transition"
          origin="center center"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              plain
              icon
            >
              <v-app-bar-nav-icon 
                  class="d-block d-sm-none"
              ></v-app-bar-nav-icon>
            </v-btn>
          </template>
            <v-list nav dense>
                <v-list-item-group value="true" active-class="primary--text text--accent">
                    <v-list-item
                        v-for="(route, i) in routes()"
                        :key="i"
                        link
                        @click="routeTo(route.path)"
                    >
                        <v-list-item-title v-text="route.name"></v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>

        <v-spacer class="d-none d-sm-block mr-1" />
        <div class="d-none d-sm-block">
            <v-btn
              v-for="route in routes()"
              :key="route.path"
              elevation="0"
              color="rgba(0,0,0,0)"
              small
              @click="routeTo(route.path)"
            >{{route.name}}</v-btn>
        </div>
        <v-spacer class="d-block d-sm-none mr-1" />
        <mb-account-menu
            class="ms-0"
            :options="{light: !darkMode, dark: darkMode}"
        />
    </v-app-bar>
    
    <v-main>
      <!-- Contents -->
      <router-view>
        <v-container :class="{'px-0': $vuetify.breakpoint.xsOnly }">
        </v-container>  
      </router-view>
    </v-main>

    <!-- Footer -->
      <v-bottom-navigation
        hide-on-scroll
        app
        height="120"
      >
        <v-card flat class="text-center pt-2">
          <v-card-text>
          <v-btn href="https://www.facebook.com/natiromisrael/" class="v-btn--active" icon plain color="blue">
            <v-icon size="24px">mdi-facebook</v-icon>
          </v-btn>
          <v-btn href="https://www.youtube.com/user/LevHaOlamIsrael?sub_confirmation=1" class="v-btn--active" plain icon color="red">  
            <v-icon size="24px">mdi-youtube</v-icon>
          </v-btn>
          <v-btn href="https://wa.me/972525665660" class="v-btn--active" icon plain color="green">  
            <v-icon size="24px">mdi-whatsapp</v-icon>
          </v-btn>
          <v-btn href="https://t.me/joinchat/TQFlQMZEc44iqkoR" class="v-btn--active" plain icon color="#37afe2">  
              <v-img height="18" width="18" contain src="./assets/telegram.png"/>
          </v-btn>
          </v-card-text>
          <v-card-text>
            {{ new Date().getFullYear() }} — <strong>Heartland of Israel</strong>
          </v-card-text>
        </v-card>
      </v-bottom-navigation>


    <mb-snackbar />

  </v-app>
</template>

<script>

export default {
  name: 'App',


  data: () => ({
    page: "home", // <- home
    drawer: false,
  }),
  computed: {
    darkMode(){return this.$route.meta.darkMode},
  },
  mounted(){
    console.log(`natirom v${process.env.VUE_APP_VERSION}`)
  },
  methods: {
    routeTo(path){
      if (this.$route.path != path) {
        this.$router.push(path)
      }
    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },
    routes() {
      return this.$router.getRoutes().filter(r => {
        return r.path.length > 1 && r.meta.menu
      }).map(r => {
        return {
          path: r.path,
          name: r.path.substring(1)
        }
      })
    }
  }
};
</script>

<style scoped>
html,body {
  padding: 0 !important;
  margin: 0 !important;
}
.container {
  padding: 0;
}
</style>