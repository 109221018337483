import video from './components/videos'
import home from './components/home';
import contact from './components/contact';
import donations from './components/donations';
import about from './components/about';

const router = {
  mode: 'history',
  routes: [
    {path: '/', meta: {darkMode: true}, component: home},
    {path: '/home', meta: {darkMode: true, menu: true}, component: home},
    {path: '/contact', component: contact, meta: {menu: true}},
    {path: '/donations', meta: {darkMode: true, menu: true}, component: donations},
    {path: '/videos', component: video, meta: {menu: true}},
    {path: '/about', component: about, meta: {menu: true}}
  ]
}

export default router