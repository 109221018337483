<template>
  <v-container fluid>

    <section class="pt-16">
      <v-layout justify-center column fill-height>
        <v-card max-width="100%" class="px-0 px-sm-16 py-16" flat>
          <v-card-text class="text-justify">
            <mb-content
              name="aboutUs"
            />
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

  </v-container>
</template>

<script>
  export default {
    name: 'about',
    data: () => ({
    }),
  }
</script>
<style>
</style>
