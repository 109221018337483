<template>
  <v-container>
<!-- Newsletter -->
    <section class="mt-6">
      <v-card flat tile class="py-8">
        <v-layout align-center justify-center column>
          <v-card-text class="mt-6">
            <mb-content
              name="contactMessage"
            />
          </v-card-text>
        </v-layout>
      </v-card>

      <v-divider inset class="ma-6"></v-divider>

      <v-card flat>
        <v-layout align-center justify-center column>
          <v-card-title>Stay in the loop</v-card-title>
          <v-card-subtitle>sign up for our newsletter</v-card-subtitle>
          <v-card-text class="mt-6">
            <mb-mailchimp-subscribe ref="contact" :options="mailchimpOptions"/>
          </v-card-text>
        </v-layout>
      </v-card>
    </section>


  </v-container>
</template>

<script>
  export default {
    name: 'homepage',
    created(){
      document.title = "Heartland of Israel"
    },
    data: () => ({
      mailchimpOptions: {

        outlined: true,
      }
    })
  }
</script>

<style>
  .wide {
    width: auto;
  }

  .gradient-fade {
    background: linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);
  }
</style>





