<template>
  <v-container fluid>

    <v-parallax
      src="../assets/vineyard.jpg"
      :height="$vuetify.breakpoint.xs ? '130' : '200'"
    >
      <v-layout align-center justify-center column fill-height>
        <h1 class="white--text mb-3 text-h5 font-weight-black text-uppercase">Support our work</h1>
      </v-layout>
    </v-parallax>
    
    <v-card
      flat
      class="d-flex justify-center"
    >
      <v-card 
        flat
        max-width="800"
      >
        <v-card-title
          style="word-break: normal;"
        >
          Partner with us in the ongoing work to Stand for the Heartland of Israel! Set up a monthly donation. Your monthly or one-time donation will help provide these activities and needs:
        </v-card-title>
        <v-card-text
          class="d-flex flex-row flex-wrap justify-center"
        >
          <v-card
            v-for="(skill, i) in skills"
            :key="i"
            min-width="380"
            flat
            class="d-flex"
          >

            <v-chip 
              class="ma-1"
              color="primary"
              outlined
              pill
            >
              <v-icon left 
                style="stroke: currentColor;"
                color="primary"
              >
                $grapevine
              </v-icon>
              {{ skill }}
            </v-chip>
          </v-card>
          <v-card-subtitle
            class="d-flex justify-center"
          >
            ...and many more activities that contribute to fulfilling prophecy and keeping the promise.
          </v-card-subtitle>
        </v-card-text>
      </v-card>
    </v-card>

    <mb-market
      :product="options"
      small
    />
  </v-container>
</template>

<script>

  export default {
    name: 'donations',
     data: () => ({
      options: {
        card: {
            class: ""
        },
      },
      skills: [
        'Legal advice for pioneers',
        'Legal defense of Jewish human rights',
        'Redeeming the land of Israel',
        'Agriculture projects',
        'Humanitarian needs for Jewish pioneer families',
        'Establishing new villages',
        'Planting orchards',
        'Health equipment for small villages',
        'Fighting BDS on behalf of small businesses',
        'Small office and staff support',
      ]
    }),

  }
</script>
