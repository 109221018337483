<template>
  <v-container height="100%" fluid>

    <!-- Video Background -->
    <section height="100">
      <video-background
        :src="`${mbImgUrl}/assets/homevideo.mp4`"
        :poster="require('@/assets/vineyard.jpg')"
        :sources="[{src: `${mbImgUrl}/assets/homevideo-mobile.mp4`, res: 900, autoplay: true}]"
        style="max-height: 500px; height: 130vh;"
        overlay="linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.0), rgba(0,0,0,0.7))"
      >
        <v-layout align-center justify-center column fill-height class="pa-16">
          <h1 class="white--text mb-3 text-h5 font-weight-black text-uppercase" style="text-align: center;">
              Stand for the Heartland of Israel
          </h1>
        </v-layout>
      </video-background>
    </section>
    
    <!-- Hello -->
    <!-- About -->
    <section>
        <v-row no-gutters>

          <v-col sm="5" class="d-none d-sm-block">
            <v-carousel height="500px" cycle hide hide-delimiter-background continuous interval="3000">
              <v-carousel-item>
                <v-img height="100%" src="../assets/flags.jpg" />
              </v-carousel-item>
              <v-carousel-item>
                <v-img height="100%" src="../assets/vineyardFlag.jpg"></v-img>
              </v-carousel-item>
              <v-carousel-item>
                <v-img height="100%" src="../assets/nati.jpg"></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col cols="auto" sm="7">
            <v-card elevation="0" class="px-3 px-sm-6 py-16 text-justify" width="100%" height="100%" tile>
              <v-layout justify-center column fill-height>
                <v-card-text>
                <mb-content
                  name="aboutHome"
                  dialog
                />
                </v-card-text>
                <v-card-text class="ml-3">
                    <v-btn
                      color="primary"
                      elevation="0"
                      @click="$router.push('/about')"
                    >More about us
                    </v-btn>
                </v-card-text>
              </v-layout>
            </v-card>
          </v-col>

        </v-row>
    </section>

    <!-- Donations -->
    <section>
      <v-parallax src="../assets/landscape.jpg">
        <v-layout class="gradient-fade pa-16" fill-height justify-center column>
          <v-card-title class="font-weight-bold text-h5">Help support our work</v-card-title>
          <v-card-text>
            <v-btn
              color="primary"
              @click="$router.push('/donations')"
            >help us out</v-btn></v-card-text>
        </v-layout>
      </v-parallax>
    </section>


  </v-container>
</template>

<script>
  export default {
    name: 'homepage',
    created(){
      document.title = "Heartland of Israel"
    },
    data: () => ({
      mailchimpOptions: {
        solo: true,
        flat: true,
      },
      mbImgUrl: 'https://merchbase-nati.nyc3.digitaloceanspaces.com'
    })
  }
</script>

<style>
  .wide {
    width: auto;
  }
  .gradient-fade {
    background: linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 50%);
  }
</style>